.ant-card,
.card-wrapper {
  margin-bottom: 24px;
  box-shadow: 0 0 18px 0 rgba(10, 31, 68, 0.12);
  border-radius: 5px;
}
.card-wrapper {
  background-color: #fff;
}
.card-wrapper .ant-card {
  margin: 0;
  padding: 0;
  border: 0;
  box-shadow: none;
}
.ant-card-body {
  padding: 5px 20px 20px 20px;
}
.ant-card-head {
  border-bottom: 0;
  margin: 0;
  min-height: 40px;
}
.ant-card-head-title {
  padding-bottom: 0;
}
.ant-card-extra {
  padding: 16px 0 0;
  a {
    color: #8a94a6;
  }
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 0;
}
.ant-list-item {
  padding: 12px 12px;
  border-radius: 5px;
}
.avatar-text {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;

  .ant-avatar {
    //float: left;
    margin-right: 0.5rem;
  }
}

.row-editable {
  cursor: pointer;

  > td {
    &:hover {
      background: #e6f7ff !important;
    }
  }
}

.row-non-editable {
  &:hover {
    > td {
      background: white !important;
    }
  }
}
