@import './sections';
@import './fonts';

* {
  letter-spacing: 0.5px;
}

html, body {
  margin: 0;
  padding: 0;
  letter-spacing: 0.5px;
  line-height: 1.5715;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    -webkit-border-radius: 100px;
  }

  ::-webkit-scrollbar:hover {
    background-color: #bcc2cb40;
  }

  ::-webkit-scrollbar-thumb:vertical {
    background: #bcc2cb;
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 30px;
  }

  ::-webkit-scrollbar-thumb:vertical:active {
    background: #949ba1;
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    min-height: 30px;
  }

  ::-webkit-scrollbar-thumb:horizontal {
    background: #bcc2cb;
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    border: 2px solid rgba(0, 0, 0, 0);
    min-height: 30px;
  }

  ::-webkit-scrollbar-thumb:horizontal:active {
    background: #949ba1;
    -webkit-border-radius: 100px;
    background-clip: padding-box;
    min-height: 30px;
  }
}

#root {
  height: 100vh;
}

.ant-notification {
  z-index: 99999999;
}

.ant-table-body {
  scrollbar-width: auto;
  scrollbar-color: auto;
}

.react-horizontal-scrolling-menu--wrapper {
  .react-horizontal-scrolling-menu--scroll-container {
    overflow: hidden;
  }
}