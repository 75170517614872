$color: #01c6d2;

.r-loader-wrapper {
  display: inline-flex;
  align-items: center;
  height: 100%;

  > span {
    margin-left: 5px;
    color: #314362;
    text-align: left;
  }

  .r-circular-loader {
    animation: rotate 2s linear infinite;
    transform-origin: center center;

    .r-loader-path {
      stroke-dasharray: 150,200;
      stroke-dashoffset: -10;
      animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
      stroke-linecap: round;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89,200;
    stroke-dashoffset: -124;
  }
}

@keyframes color {
  0% {
    stroke: $color;
  }
  40% {
    stroke: $color;
  }
  66% {
    stroke: $color;
  }
  80%, 90% {
    stroke: $color;
  }
}
