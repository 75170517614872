.mention-notification {
  cursor: pointer;
  border-left: 5px solid #01c7d2;
  user-select: none;

  .mentioned-user {
    text-shadow: #e0e0e0 1px 1px 0;
    color: #01c7d2;
  }

  .mention-notification-content {
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    .chat-mentions-mention {
      color: #1890ff;
      background-color: rgba(26, 144, 255, 0.1);
      padding: 2px 4px;
      border-radius: 4px;
      position: relative;
      z-index: 1;

      &__highlighted {
        background-color: rgba(255, 193, 7, 0.5);
        color: #0261b9;
      }
    }
  }
}
