@import "../../styles/colors";

.reminder-notification {
  cursor: pointer;
  border-left: 5px solid #01c7d2;
  user-select: none;
  padding: 12px !important;

  .reminder-notification-content {
    > p {
      margin: 0;
      font-size: 16px;
    }

    > span {
      font-size: 14px;
      color: $gray;
    }
  }

  .reminder-notification-icon {
    margin-left: 5px;
    margin-top: 2px;
  }
}
