@import "../../styles/colors";

.main-layout {
  background-color: #f6f8fb !important;
  position: relative;
  display: flex;
  flex-direction: row;

  .main-content {
    position: relative;
    width: calc(100% - 250px);
    height: 100%;
    background-color: #f6f8fb;
    padding: 0 20px 20px;
    flex: 1;
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 45px;
  }

  .ant-menu-inline-collapsed > .ant-menu-item .anticon {
    line-height: 22px;
    height: 30px;
  }
}

@media (max-width: 768px) {
  .main-layout {
    flex-direction: column;

    .main-content {
      width: 100%;
    }

    .header-search {
      margin-bottom: 0;
    }
  }
}
