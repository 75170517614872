@import "../../styles/colors";

.r-select-icon {
  margin-left: 10px;
}

.r-select-container {
  > div {
    cursor: pointer;
    min-height: 40px;
  }
}

.r-select-label {
  color: #4e5d78;
  font-size: 12px;
  line-height: 10px;
  display: inline-block;
  margin-bottom: 5px;

  .r-select-asterisk {
    color: #ff4d4f;
    margin-right: 5px;
  }
}

.r-select-error {
  color: #ff4d4f;
  font-size: 12px;
  line-height: 16px;
  display: inline-block;
  margin-top: 4px;
}

.r-select-option {
  display: flex;

  .r-select-option-icon {
    display: inline-block;
    margin-right: 8px;
    color: $black;
  }

  &__danger {
    color: #ff4d4f;

    .r-select-option-icon {
      color: #ff4d4f;
    }
  }
}

.r-select-blue-highlight {
  > div {
    &:first-of-type {
      border: 2px solid $primary;
      box-shadow: none !important;
      background-color: #f3f7ff !important;
    }
  }
}

.r-select-red-highlight {
  > div {
    &:first-of-type {
      border: 2px solid $red;
      box-shadow: none !important;
      background-color: #fff2f2 !important;
    }
  }
}

.r-select {
  font-size: 14px;

  &__disabled {
    > div > div {
      background-color: #F8F9FB;
    }
  }
}
