@import "../../styles/colors";

@mixin expanded () {
  width: 250px;

  .nav-bar-heading {
    .nav-bar-logo {
      margin-top: -5px;
      margin-left: 15px;
      width: 120px;
      position: initial;
      pointer-events: initial;

      .ramp-logo-letter {
        display: block;
      }
    }
  }

  .nav-bar-body {
    margin-top: 18px;

    .nav-bar-account {
      color: $gray;
      padding: 12px 20px 20px 20px;
      display: flex;
      align-items: flex-start;
      min-width: 250px;
      font-size: 14px;

      .nav-bar-toggle {
        top: 7px;
        right: 10px;
      }
    }

    .nav-bar-content {
      height: calc(100vh - 164px);
      overflow-x: hidden;
      overflow-y: auto;

      .nav-item-wrapper {
        margin: 8px 0;

        .nav-item-content {
          border-radius: 5px;
          flex-direction: row;
          margin: 0 12px;
          padding: 4px;
          font-size: 14px;

          > span {
            display: block;
            color: $gray;
          }

          .nav-item-logo {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

.nav-bar-wrapper {
  padding: 30px 0 30px 0;
  width: 70px;
  height: 100vh;
  position: sticky;
  top: 0;
  background-color: #fff;
  box-shadow: $shadow;
  z-index: 999;
  overflow: hidden;
  transition: width 0.1s ease-in-out;

  .nav-bar-heading {
    display: flex;
    align-items: center;

    > svg {
      cursor: pointer;
    }

    .nav-bar-burger {
      color: #90969e;
      width: 28px;
      margin-right: 20px;
    }

    .nav-bar-logo {
      width: 110px;
      height: 45px;
      position: absolute;
      top: 30px;
      left: 24px;

      .ramp-logo-letter {
        display: none;
      }
    }
  }

  .nav-bar-body {
    margin-top: 110px;
    position: relative;

    .nav-bar-account {
      > span {
        max-width: 180px;
        color: $gray;
      }
    }

    .nav-bar-content {
      height: calc(100vh - 164px);
      overflow-x: hidden;
      overflow-y: auto;
    }

    .nav-bar-toggle {
      flex: none;
      transform: rotate(90deg);
      width: 22px;
      height: 22px;
      cursor: pointer;
      position: absolute;
      top: -45px;
      right: 20px;
      border-radius: 4px;
      padding: 3px;
      box-sizing: content-box;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background: $hover-dark;
      }

      &__collapsed {
        transform: rotate(-90deg);
      }
    }
  }

  &__mobile {
    width: 100%;
    height: initial;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 15px 0 15px 0;
    background: #fff;
    border-bottom: 1px solid $light-gray;

    .nav-bar-heading {
      justify-content: space-between;
      flex-direction: row-reverse;

      .nav-bar-logo {
        width: 120px;
        left: 10px;
        top: 10px;
        pointer-events: initial;

        .ramp-logo-letter {
          display: block;
        }
      }
    }

    .nav-bar-body {
      margin-top: 40px;

      .nav-bar-account {
        padding: 0 30px;
        margin-bottom: 20px;
      }

      .nav-bar-content {
        .nav-item-wrapper {
          margin: 8px 0;

          .nav-item-content {
            border-radius: 5px;
            flex-direction: row;
            margin: 0 20px;
            padding: 2px;
            font-size: 14px;

            .nav-item-logo {
              margin-right: 10px;
            }

            > span {
              display: block;
              color: $gray;
            }
          }
        }
      }
    }
  }

  &__mobile_active {
    background: #fff;
    height: 100%;
  }

  &__expanded {
    @include expanded();
  }
}

@media (min-width: 1200px) {
  //.nav-bar-wrapper {
  //  @include expanded();
  //}
}

.nav-bar-slider-wrapper {
  .ant-drawer-body {
    padding: 0;
  }
}

.sidebar-nav-item-tooltip {
  padding-left: 0;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    background-color: $black;
  }
}
