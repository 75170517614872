@font-face {
  font-family: 'JetBrainsMono-Bold';
  src: url(../assets/fonts/JetBrainsMono-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Light.woff2) format('woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Medium.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url(../assets/fonts/Inter-Bold.woff2) format('woff2');
  font-weight: 800;
  font-style: normal;
}