.error-boundary-container {
  background-color: #f3f3f4;
  width: 100%;
  height: 100%;

  .error-boundary-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .error-boundary-details-container {
      max-width: 800px;
      max-height: 500px;
      width: 100%;
      height: 100%;
      background-color: #fff;
      padding: 25px;
      margin: 25px;
      border-left: 4px solid #01c6d2;
      box-shadow: 0 0 18px 0 rgba(10,31,68,.12);

      .error-boundary-details-heading {
        display: flex;
        align-items: center;
        flex-direction: column;

        > span {
          font-size: 80px;
          color: #01c6d2;
          margin-bottom: 10px;
        }

        > div {
          text-align: center;

          > h2 {
            margin: 0;
          }

          > p {
            color: #F03D3D;
            margin: 0;
            max-width: 700px;
            border: 1px solid #dad6d6;
            padding: 5px 15px;
            display: inline-block;
            border-radius: 4px;
            background: #f3f3f4;
            white-space: pre-wrap;
            max-height: 200px;
            overflow: auto;
          }
        }
      }

      .error-boundary-details-body {
        text-align: center;
        margin-top: 40px;

        > p {
          font-size: 15px;
          color: #000;
          line-height: 18px;
          max-width: 500px;
          margin: 0 auto 20px;
        }

        > a {
          cursor: pointer;
          padding: 8px 18px;
          border-radius: 25px;
          background-color: #01c6d2;
          color: #fff;
          user-select: none;
          box-shadow: 0 0 18px 0 rgba(10, 31, 68, 0.12);
          text-decoration: none;

          > span {
            font-size: 16px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
