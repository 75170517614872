@import "../../../styles/colors";

@mixin activeNav() {
  .nav-item-content {
    background-color: #e6e8f1 !important;

    > span {
      color: $black !important;
    }
  }
}

@mixin hoverNav() {
    background-color: rgba(230, 232, 241, 0.4);

    > span {
      color: $black !important;
    }
}

@mixin subNavActive() {
  .sub-nav-item__active {
    background-color: #e6e8f1 !important;
  }
  .sub-nav-item:hover {
    background-color: rgba(230, 232, 241, 0.4);
  }
}
.nav-item-wrapper__active {
  @include activeNav();
}

.nav-item-wrapper {
  cursor: pointer;
  color: $black;
  margin: 8px 0;
  //padding: 5px 0;
  user-select: none;
  min-width: 250px;
  //transition: background-color 0.2s ease-in-out;

  .nav-item-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 13px;
    position: relative;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      @include hoverNav();
    }

    .sub-nav-item-unread-message {
      width: 8px;
      min-width: 8px;
      height: 8px;
      min-height: 8px;
      background-color: #cc4b4c;
      border-radius: 100%;
      position: absolute;
      right: 15px;
      top: 15px;
      box-shadow: $shadow;
    }

    > span {
      display: none;
    }

    .nav-item-logo {
      width: 34px;
      height: 34px;
      padding: 7px;

      > svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  .sub-nav-item-wrapper {
    margin-top: 10px;
    font-size: 14px;

    @include subNavActive();
  
    .sub-nav-item {
      padding: 10px 0 10px 42px;
      margin:0 auto 1px;
      border-radius:5px;
      width:calc(100% - 24px);
      transition: background-color 0.2s ease-in-out;

      &:hover {
        @include subNavActive();
      }
    }
  }

  //&:hover {
  //  background-color: #f6f7fc;
  //}

  &__sub {
    flex-direction: column;
    padding-left: 0;
    position: relative;

    .nav-item-content {

      .nav-item-chevron {
        display: block;
        position: absolute;
        top: 8px;
        right: 10px;
        width: 20px;
        transition: transform 0.2s ease-in-out;

        &__opened {
          transform: rotate(180deg);
        }
      }
    }
   
    .sub-nav-item-wrapper {
      .sub-nav-item {
        color: $gray;
        padding-left: 60px;
      }
    }
    
    &.nav-item-wrapper__collapsed {

      .nav-item-content {
        .nav-item-chevron {
          display: none;

          &__opened {
            transform: rotate(-90deg);
          }
        }
      }

      .sub-nav-item-wrapper {
        background-color: #fff;
        position: absolute;
        top: 0;
        left: 75px;
        box-shadow: $shadow;

        .sub-nav-item {
          padding: 10px 10px 10px 20px;
          width: 150px;
        }
      }
    }

    @media (max-width: 768px) {
      .sub-nav-item-wrapper {
        .sub-nav-item {
          padding-left: 65px;
        }
      }
    }
  }

  &__collapsed {
    min-width: 70px;

    .nav-item-content {
      border-radius: 5px;
      margin: 0 12px;
      padding: 4px;
    }
  }
}
