$primary: #2451f5;
$black: #0A1F44;
$info: #0284fe;
$green: #0bb07b;
$red: #f03d3d;
$warning: #ffad0d;

$gray: #53627C;
$light-gray: #e8eaed;
$white: #f6f7f8;
$hover: #f7f9fc;
$hover-dark: #dbe2e9;
$border: #ebf0f5;
$purple: #5c20c4;
$purple-dark: #4a17a6;

$shadow: 0 0 4px 0 rgba(10, 31, 68, 0.08), 0 1px 1px 0 rgba(10, 31, 68, 0.08);
$radius: 4px;
